<template>
  <div class="input-group">
    <input
      type="text"
      readonly
      class="form-control text-right py-4"
      :placeholder="$t('search-tab.choose-date')"
      @click="toggleCalendar"
      style="cursor: auto"
      :value="inputText"
      ref="inputControl"
      dir="ltr"
    />
    <!-- <div class="input-group-append">
      <span class="input-group-text">
        <i class="far fa-calendar-alt"></i>
      </span>
    </div> -->
    <div class="col-12 calendar-body" v-if="show">
      <div class="card mb-2 calendar-card">
        <div class="card-text p-2">
          <div class="row">
            <div v-for="(monthYear, i) of monthYears" :key="i" class="month-container" :style="{ width: 100 / noOfMonthsInRow + '%' }">
              <table>
                <thead>
                  <tr>
                    <th class="text-center month-title" :colspan="weekDays.length">
                      <span v-if="i == 0" class="float-left" style="cursor: pointer; padding-left: 8px" @click="nextMonth">
                        <i class="arrow left"></i>
                      </span>
                      {{ displayMonthYear(monthYear) }}
                      <span v-if="(monthYear.month() > thisMonth || monthYear.year() > thisYear) && (noOfMonthsInRow == 1 && i == 0) || (noOfMonthsInRow == 2 && i == 1)"
                      class="float-right" style="cursor: pointer; padding-right: 8px" @click="previousMonth">
                        <i class="arrow right"></i>
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th v-for="(weekDay, i) of weekDays" :key="i" class="day-header text-center">
                      {{ weekDay }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(daysOfWeek, i) of weekWiseDays(monthYear)" :key="i">
                    <td
                      v-for="(day, j) of daysOfWeek"
                      :key="j"
                      class="text-center"
                      :class="{
                        available: isAvailable(day)
                          ? true
                          : false,
                        'start-date': isStartDate(day),
                        'end-date': isEndDate(day),
                        'under-selection': isUnderSelection(
                          day
                        ),
                        today: isToday(day)
                      }"
                      @click="
                        isAvailable(day)
                          ? selectDate(day)
                          : null
                      "
                      @mouseenter="
                        isAvailable(day)
                          ? enterDate(day)
                          : null
                      "
                    >
                      <div v-if="day">
                        {{ day.format("D") }}
                        <!-- <div class="price-text">
                          {{ dayPrice(day) }}
                        </div> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="flex-fill m-1" :class="{ 'm-1': type !== 'side' }">
              <button
                class="btn btn-secondary btn-sm btn-block"
                @click="clearDatesSelection"
                :disabled="
                  selectionDate1 == null ? true : false
                ">
                {{ $t("calendar.clear") }}
              </button>
            </div>
            <div class="flex-fill m-1">
              <button class="btn btn-primary btn-sm btn-block" @click="finalizeSelection" :disabled="selectionDate1 && selectionDate2 ? false : true">
                {{ $t("calendar.continue") }}
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

require('dayjs/locale/he');
require('dayjs/locale/ru');
require('dayjs/locale/ar');

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  props: {
    availableDates: {
      type: Array,
      required: true,
    },
    calendarShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'tabs',
    },
  },
  data() {
    return {
      tempSelectionDate2: null,
      weekDays: [],
      startMonthYear: dayjs().format('YYYY-MM'),
      today: dayjs().startOf('day'),
      show: false,
      mode: 'startSelect',
      endDatesInfo: [],
      containerWidth: 0,
      query: this.$route.query,
    };
  },
  created() {
    dayjs.locale(this.lang);
    this.getWeekName();

    window.addEventListener('resize', this.refreshContainerSize);
  },
  mounted() {
    this.refreshContainerSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.refreshContainerSize);
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    thisMonth() {
      return dayjs().month();
    },
    thisYear() {
      return dayjs().year();
    },
    monthYears() {
      const monthYears = [];
      for (let i = 0; i < 1; i += 1) {
        const monthYear = dayjs(`${this.startMonthYear}-01`).add(i, 'months');
        monthYears.push(monthYear);
      }
      return monthYears;
    },
    inputText() {
      let text = '';
      if (this.selectionDate1) {
        text += `${this.selectionDate1.format('DD/MM/YY')} - `;
      }
      if (this.selectionDate2) {
        text += this.selectionDate2.format('DD/MM/YY');
      }
      return text;
    },
    noOfMonthsInRow() {
      let slots = Math.floor(this.containerWidth / 240);
      if (slots > 2) {
        slots = 2;
      }
      return slots;
    },
    selectionDate1: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.from
          ? this.$store.getters.GET_SEARCH_CONTENT.from
          : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['from', value]);
      },
    },
    selectionDate2: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.to
          ? this.$store.getters.GET_SEARCH_CONTENT.to
          : '';
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['to', value]);
      },
    },
  },
  watch: {
    selectionDate1() {
      this.emitDates();
    },
    selectionDate2() {
      this.emitDates();
    },
    lang() {
      dayjs.locale(this.lang);
      this.getWeekName();
      this.nextMonth();
      this.previousMonth();
    },
    calendarShow() {
      this.clearDatesSelection();
      this.show = this.calendarShow;
    },
    availableDates() {
      this.clearDatesSelection();
    },
  },
  methods: {
    setStartDate() {
      const startDate = dayjs(this.availableDates[0]).startOf('day');
      if (this.selectionDate1) {
        this.startMonthYear = dayjs(this.selectionDate1).format(
          'YYYY-MM',
        );
      } else {
        this.startMonthYear = dayjs(startDate).format('YYYY-MM');
      }
    },
    getWeekName() {
      this.weekDays = [
        this.$t('weekShortName.sun'),
        this.$t('weekShortName.mon'),
        this.$t('weekShortName.tue'),
        this.$t('weekShortName.wed'),
        this.$t('weekShortName.thu'),
        this.$t('weekShortName.fri'),
        this.$t('weekShortName.sat'),
      ];
    },
    emitDates() {
      if (this.selectionDate1) {
        this.startMonthYear = dayjs(this.selectionDate1).format(
          'YYYY-MM',
        );
      }
      if (this.selectionDate1 && this.selectionDate1.get('date') === this.selectionDate1.daysInMonth()) { this.nextMonth(); }
      this.$emit('dates', {
        from: this.selectionDate1 ? this.selectionDate1.format('YYYY-MM-DD') : '',
        to: this.selectionDate2 ? this.selectionDate2.format('YYYY-MM-DD') : '',
      });
    },
    weekWiseDays(monthYear) {
      const days = [];
      let day = monthYear.clone();
      while (day.format('YYYY-MM') === monthYear.format('YYYY-MM')) {
        days.push(day);
        day = day.add(1, 'days');
      }

      const weekWiseDays = [];
      let i = 0;
      days.forEach((dday) => {
        if (!weekWiseDays[i]) {
          weekWiseDays[i] = [];
        }
        const position = dday.day();
        if (position != null) {
          weekWiseDays[i][position] = dday;
          if (position === this.weekDays.length - 1) {
            i += 1;
          }
        }
      });
      return weekWiseDays;
    },
    displayMonthYear(monthYear) {
      return `${monthYear.format('MMMM')} ${monthYear.format('YYYY')}`;
    },
    toggleCalendar() {
      this.show = !this.show;
      if (this.show) {
        this.mode = 'startSelect';
      }
      this.refreshContainerSize();
      this.setStartDate();
    },
    nextMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`)
        .add(1, 'months')
        .format('YYYY-MM');
    },
    previousMonth() {
      this.startMonthYear = dayjs(`${this.startMonthYear}-01`)
        .subtract(1, 'months')
        .format('YYYY-MM');
    },
    isAvailable(day) {
      if (!day) {
        return false;
      }
      const startDate = dayjs(this.availableDates[0]).startOf('day');
      const endDate = dayjs(this.availableDates[1]).startOf('day');
      if (!day.isSameOrAfter(startDate) || !day.isSameOrBefore(endDate)) {
        return false;
      }
      return true;
    },
    enterDate(day) {
      if (this.mode === 'endSelect') {
        this.tempSelectionDate2 = day;
      }
    },
    selectDate(day) {
      if (this.mode === 'startSelect') {
        this.selectionDate1 = day;
        this.selectionDate2 = null;
        this.mode = 'endSelect';
      } else {
        if (day.isSameOrBefore(this.selectionDate1)) return;
        this.selectionDate2 = day;
        this.tempSelectionDate2 = null;
        this.mode = 'startSelect';
      }
    },
    isStartDate(day) {
      if (!day) {
        return false;
      }
      return (
        this.selectionDate1
        && this.selectionDate1.format('YYYY-MM-DD')
          === day.format('YYYY-MM-DD')
      );
    },
    isEndDate(day) {
      if (!day) {
        return false;
      }
      return (
        this.selectionDate2
        && this.selectionDate2.format('YYYY-MM-DD')
          === day.format('YYYY-MM-DD')
      );
    },
    isToday(day) {
      if (!day) {
        return false;
      }
      return this.today.format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
    },
    clearDatesSelection() {
      this.selectionDate1 = null;
      this.selectionDate2 = null;
      this.mode = 'startSelect';
      this.$emit('finalDateSelect', false);
    },
    finalizeSelection() {
      this.show = false;
      this.$emit('finalDateSelect', true);
    },
    isUnderSelection(day) {
      if (!day) {
        return false;
      }
      if (this.mode === 'endSelect') {
        return (
          this.selectionDate1
          && this.tempSelectionDate2
          && day.isSameOrAfter(this.selectionDate1)
          && day.isSameOrBefore(this.tempSelectionDate2)
        );
      } else {
        return (
          this.selectionDate1
          && this.selectionDate2
          && day.isSameOrAfter(this.selectionDate1)
          && day.isSameOrBefore(this.selectionDate2)
        );
      }
    },
    refreshContainerSize() {
      const width = this.$refs.inputControl.clientWidth;
      this.containerWidth = width < 250 ? 250 : width;
    },
  },
};
</script>

<style scoped>
.calendar-body .month-title {
  color: black;
}
.calendar-body {
  position: absolute;
  top: 50px;
  z-index: 10;
}
.calendar-card {
  min-width: 250px;
}
.month-container {
  padding: 10px;
}

.month-container table {
  width: 100%;
  margin-bottom: 10px;
}

.month-container td {
  color: #c3ceda;
  pointer-events: none;
  cursor: default;
}

.today {
  color: #95a5b9;
  background-color: #ebf0f1;
}

.month-container .day-header {
  color: #0db04b;
}

.month-container td.start-date,
.month-container td.under-selection {
  background-color: #0db04b !important;
  color: #fff !important;
}
.month-container td.start-date div {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #0db04b !important;
}
.month-container td.start-date {
  background-color: #c2ebd2 !important;
}
.month-container td.end-date div {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #0db04b !important;
}
.month-container td.end-date {
  background-color: #c2ebd2 !important;
}

.month-container td.available {
  pointer-events: all;
  background-color: #c2ebd2;
  color: #000;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.reference.available,
.month-container td.available {
  pointer-events: all;
  background-color: #e0efff;
  color: #000;
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  /* background-image: linear-gradient(-45deg, #e0efff 65%, #0db04b 35%); */
  /* background-image: linear-gradient(-45deg, #b6ddff, #fff, #b6ddff); */
  /* border-radius: 3px; */
}

.month-container td.holiday:after {
  content: "";
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}
.reference.holiday,
.month-container td.holiday:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDY5LjU1IDY5LjU1Ij48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNjkuNTUgMCAwIDY5LjU1VjBoNjkuNTVabTAgMCIgc3R5bGU9ImZpbGw6IzAxNzJkZiIvPjxwYXRoIGNsYXNzPSJjbHMtMiIgZD0iTTkuNzQgMjUuNDFhNi4xOCA2LjE4IDAgMCAxIDEuNDgtMS4xNSAyLjczIDIuNzMgMCAwIDEgMS4zNC0uMzMgMi42NSAyLjY1IDAgMCAxIDEuMjkuNCA2LjgyIDYuODIgMCAwIDEgMS4zMSAxLjA2bDYuMTEgNi4xMWExMi43NyAxMi43NyAwIDAgMCAyLjQxIDEuOSAxNC44IDE0LjggMCAwIDAgMi40NCAxLjIxTDIzLjcyIDM3YTUgNSAwIDAgMS0xLjM3LS41OSAxMi4yOSAxMi4yOSAwIDAgMS0xLjI3LS44NmMtLjQ0LS4zNS0uODctLjcyLTEuMy0xLjExYTcuNDYgNy40NiAwIDAgMSAuODEgMS42IDUuMTkgNS4xOSAwIDAgMSAuMzIgMS45NiA0LjI3IDQuMjcgMCAwIDEtLjQ0IDEuODkgNi41MiA2LjUyIDAgMCAxLTEuMjYgMS43bC0xLjA3IDEuMDEtMi4yMS0yLjIxIDEuMzEtMS4zYTQuNCA0LjQgMCAwIDAgMS0xLjg3IDQuNzUgNC43NSAwIDAgMCAuMS0xLjkxIDUuNDYgNS40NiAwIDAgMC0uNjQtMS44MSA3Ljg3IDcuODcgMCAwIDAtMS4yOS0xLjY4bC0zLjc2LTMuNzZhMSAxIDAgMCAwLS41OS0uMzUuOS45IDAgMCAwLS42NC4zNWwtMi4xNCAyLjE4LTIuMTgtMi4xOVpNMzAuNTIgOWwtMS4xNyAxLjE3TDQwIDIwLjc5bC0yLjYzIDIuNjEtMTAuNjUtMTAuNjUtNC44NCA0Ljg0IDEwLjY0IDEwLjY1LTIuNjIgMi42Mi0xMC42Mi0xMC42Mi0xLjIxIDEuMjEtMi4xOS0yLjE5TDI4LjM0IDYuODFaIi8+PC9zdmc+);
  background-position: 0 0;
  background-repeat: no-repeat;
}

.month-container td .price-text {
  font-size: 9px;
}

.month-container table {
  width: 100%;
}

.month-container th,
.month-container td > div {
  padding: 5px;
}
.month-container td {
  padding: 0;
  position: relative;
}

.month-title {
  font-weight: bolder;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.reference-list li {
  list-style: none;
}

.reference {
  width: 18px;
  height: 18px;
}
</style>
